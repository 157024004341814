export default {
  "footer_company": "Fara connect",
  "title": "Fara connect IoT Platform",
  "webshop": "bluecherry",
  "header_logo": "farra_connect_logo_header.svg",
  "sidenav_logo": "farra_connect_sidenav_logo.svg",
  "sidenav_text": "<h1> <span>Connect.</span> <strong>Temperature.</strong> <span>Anywhere.</span> </h1>",
  "use_proxy": true,
  "show_footer_logo": true,
  "company": "Faraconnect"
}

